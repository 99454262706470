<script setup>
useSeoMeta({
    title: 'LNF',
    ogTitle: 'LNF',
    description: null,
    keywords: null,
    ogDescription: null,
    ogImage: '/images/logo.svg',
    twitterCard: null,
});

definePageMeta({
    layout: 'pending',
});
</script>

<template>
    <div class="text-center container space-y-8 my-12">
        <div class="flex items-center place-content-center">
            <NuxtImg alt="LNF" class="h-36" src="/images/logo.svg" />
        </div>
        <div class="font-extrabold text-5xl uppercase font-black">Under Construction</div>
        <div class="flex items-center place-content-center">
            <NuxtImg alt="LNF" src="/images/under.png" />
        </div>
        <div class="text-lg uppercase">We are coming soon</div>
    </div>
</template>
